<template>
  <div class="login-container" id="app">
    <div class="login-form">
      <el-form ref="loginFormRef"
               :model="loginForm"
               :rules="loginFormRules"
               label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username" placeholder="请填写用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="请填写密码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="checkCode">
          <el-col :span="14">
             <el-input type="text" v-model="loginForm.verifycodetoken" placeholder="验证码"  style="width:60%;display:inline-block"></el-input>
             <el-button type="danger" size="mini" @click="refreshCode" style="margin-left:5px">刷新</el-button>
          </el-col>
          <el-col :span="10">
             <!--验证码组件-->
             <s-identify :identifyCode="identifyCode"></s-identify>
          </el-col>
        </el-form-item>
        <!-- <el-row :gutter="35">
            <el-col :span="14">
                <el-form-item label="验证码" prop="code">
                <el-input v-model="loginForm.code" placeholder="请输入验证码"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="10">
                <el-image  :src='verifyCode' ref='codeImage'
                @click="refresh()"
                style='width:100px;height:30px;border:1px solid #ffffff' ></el-image>
            </el-col>
        </el-row> -->
        <el-form-item>
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="success" @click="zhuce">注册</el-button>
          <el-button type="danger" @click="forgetPassword">忘记密码</el-button>
          <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import user from '../api/user'
import SIdentify from '../components/SIdentify'
  export default {
    name:'#app',
    components:{ SIdentify},
    data() {
      return {
        verifyCode:'',
        //验证码
        identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',//随机串内容
        identifyCode: '',
        loginForm: {
          username: '',
          password: '',
          code:'',
          verifycodetoken:''
        },
        loginFormRules: {
          username: [
              {required: true, message: '请输入用户名', trigger: 'blur'},
              {min: 4, max: 30, message: '长度在4-30个字符', trigger: 'blur'},
              {validator:(rule,value,callback)=>{
                const username =  /^\w+$/
                if(!username.test(value)) {
                  callback(new Error('只能是数字字母下划线'))

                }else {
                  callback()
                }
              }}
          ],
          password: [
              {required: true, message: '请输入密码', trigger: 'blur'},
              {min: 4, max: 130, message: '长度在4-30个字符', trigger: 'blur'}
          ],
          password: [
              {required: false, message: '请输入验证码', trigger: 'blur'},
              
          ]
        }
      }
    },
    created() {
    },
    mounted() {
       // 初始化验证码
       this.identifyCode = ''
       this.makeCode(this.identifyCodes, 4)
    },
    methods: {
      //验证码需要的方法
      refreshCode () {
       this.identifyCode = ''
       this.makeCode(this.identifyCodes, 4)
      },
      makeCode (o, l) {
        for (let i = 0; i < l; i++) {
          this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
        }
      },
      randomNum (min, max) {
        return Math.floor(Math.random() * (max - min) + min)
      },
      zhuce() {
        this.$router.push('/zhuce')
      },
      forgetPassword() {
        this.$router.push('/changePassword')
      },
      getCheckCode () {
        console.log('http',this.$http)
      this.$http.get('/user/checkcode',
        { responseType: 'blob' }).then(response => {
        this.loginForm.verifycodetoken = response.headers['access-token']
        const url = window.URL.createObjectURL(response.data)
        this.verifyCode = url
        console.log(this.loginForm.verifycodetoken)
        console.log('url',url)
      })
    },
      login() {

        if(this.loginForm.verifycodetoken !== this.identifyCode){
           this.$message.error('验证码不对')
           return;
        }
        this.$refs.loginFormRef.validate(valid => {
          if(valid) {
            user.Login(this.loginForm.username, this.loginForm.password)
            .then(resp =>{
              //console.log("登录信息"+JSON.stringify(resp))
              if(resp.data.status===200) {
                this.$message.success('登陆成功')
                //console.log("登录的token为"+JSON.stringify(resp.data))
                window.sessionStorage.setItem('userId', resp.data.data.id)
                window.sessionStorage.setItem('token', resp.data.data.token)
                window.sessionStorage.setItem('refleshToken', resp.data.data.refleshToken)
                window.sessionStorage.setItem('username', resp.data.data.name)
                this.$router.push('/')
              } else {
                //console.log("登录失败")
                this.$message.error(resp.data.msg);
              }
            }).catch(e =>{
              console.log(e)
            })
          } else {
            console.log('提交失败');
            return false;
          }
        })
      },
      resetLoginForm() {
        this.$refs.loginFormRef.resetFields()
      }
    },
    created() {
      this.getCheckCode()
    },
  }
</script>

<style scoped>
  .login-container {
    width: 100%;
    height: 100%;
    background-image: url('../assets/bg2.jpg');
    background-repeat: no-repeat;
    position: fixed;
    background-size: cover;

  }
  .login-form {
    width: 380px;
    height: 250px;
    position: absolute;
    top: 50%; /* 向下移动屏幕的一半 */
    left: 50%; /* 向右移动屏幕的一半 */
    transform: translate(-50%, -50%); /* 向上和向左移动自身的50% */
    padding: 30px 10px 0px 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px #CACACA;
    background-color: #FFFFFF;
  }
</style>
