<template>
    <div class="login-container">
        <el-card class="card">
            <h2>注册</h2>
            <hr>
        <el-form :model="zhucetable">
            <el-form-item label="用户名">
                <el-input type="text" v-model="zhucetable.name" />
            </el-form-item>
            <el-form-item label="密码">
                <el-input type="password" v-model="zhucetable.password" />
            </el-form-item>
             <el-form-item label="确认密码">
                <el-input type="password" v-model="zhucetable.password2" />
            </el-form-item>
             <el-form-item label="邮箱">
                <el-input type="text" v-model="zhucetable.email" />
            </el-form-item>
            <!--<el-form-item label="年龄">
                <el-input type="number" v-model="zhucetable.age" />
            </el-form-item> -->
            <el-form-item label="验证码" prop="checkCode">
               <el-col :span="10">
                 <el-input type="text" v-model="zhucetable.verifycodetoken" placeholder="验证码"  style="width:40%;display:inline-block"></el-input>
                 <!-- 前端获取验证码组件 -->
                 <!-- <el-button type="danger" size="mini" @click="refreshCode" style="margin-left:5px">刷新</el-button> -->
                 <!-- 后端获取验证码组件 -->
                 <el-button type="danger" size="mini" 
                 :loading="!canGetCode"
                 :disabled="!canGetCode || countDown > 0"
                 @click="getEmailCheckCode" style="margin-left:5px"> {{ countDown > 0 ? countDown : '获取验证码' }}</el-button>
               </el-col>
               <!-- <el-col :span="10">
                  前端 验证码组件
                 <s-identify :identifyCode="identifyCode"></s-identify>
               </el-col> -->
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">注册</el-button>
            </el-form-item>
        </el-form>
        </el-card>
    </div>
</template>

<script>
import user from '../../api/user'
import SIdentify from '../../components/SIdentify'
export default {
    name:'#app',
    components:{ SIdentify},
    data() {
        return {
            zhucetable:{
                name:'',
                age:'',
                email:'',
                password:'',
                password2:'',
                verifycodetoken:''
            },
            //验证码
            identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',//随机串内容
            identifyCode: '',
            //验证码使用验证
            canGetCode: true,
            countDown: 0
            
        }
    },
    mounted() {
       // 初始化验证码
       this.identifyCode = ''
       this.makeCode(this.identifyCodes, 4)
    },
    methods: {
         //前端 刷新 验证码需要的方法
        refreshCode () {
         this.identifyCode = ''
         this.makeCode(this.identifyCodes, 4)
        },
        //后端 获取验证码
        getEmailCheckCode () {
           if(!this.canGetCode){
               return;
           }
           this.canGetCode = false
           user.emailCheckCode(this.zhucetable.email)
            .then(resp =>{
               if (resp.data.code === 200) {
                 this.startCountDown(60);
                 this.$message.success('发送成功');
                } else {
                 this.canGetCode = true;
                  this.$message.error('发送失败请重试');
                }
            })
           .catch(() => {
             this.canGetCode = true;
             this.$message.error('发送失败请重试');
            });  
        },
        startCountDown(seconds) {
          this.countDown = seconds;
          const interval = setInterval(() => {
            if (this.countDown > 0) {
             this.countDown--;
            } else {
             clearInterval(interval);
             this.canGetCode = true;
            }
          }, 1000);
        },
        makeCode (o, l) {
         for (let i = 0; i < l; i++) {
           this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
         }
        },
        randomNum (min, max) {
         return Math.floor(Math.random() * (max - min) + min)
        },
        onSubmit() {
            //  旧验证码 -- 前端
            //  if(this.zhucetable.verifycodetoken !== this.identifyCode){
            //    this.$message.error('验证码不对')
            //    return;
            //  }
            if(this.zhucetable.password !== this.zhucetable.password2){
                this.$message.error('确认密码与密码不一致')
                return;
            }
            user.Register(this.zhucetable.name,this.zhucetable.password,this.zhucetable.email,this.zhucetable.verifycodetoken)
            .then(resp =>{
                 if(resp.data.code === 200){
                  console.log(resp)
                  this.$message.success('注册成功')
                  this.$router.push('/login')
                 }else{
                    this.$message.error(resp.data.data)
                 }
            })
        }
    },
}
</script>

<style scoped>
.card{
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    margin: auto;
    
    width: 500px;
    height: 600px;
}
.login-container {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/12174348_fc285ae4f4.jpg');
    background-repeat: no-repeat;
    position: fixed;
    background-size: cover;

  }
</style>