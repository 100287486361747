import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import qs from 'qs'
import {install} from '@icon-park/vue/es/all';
import '@icon-park/vue/styles/index.css';
import VueQriously from 'vue-qriously'
// Install
install(Vue); // use default prefix 'icon', eg: icon is People, name is icon-people.
install(Vue, 'i'); // use custom prefix 'i', eg: icon is People, name is i-people.
Vue.use(ElementUI)
Vue.use(VueQriously)

import TreeTable from 'vue-table-with-tree-grid'
Vue.component('tree-table', TreeTable)
Vue.config.productionTip = false
import './assets/css/et.css'
//开发
//axios.defaults.baseURL='http://127.0.0.1:9998'
//生产
axios.defaults.baseURL='https://hou.rtld.cc'
Vue.prototype.$http = axios
Vue.prototype.$qs = qs

//原型: 为所有的Vue实例添加baseUrl属性
//开发
//Vue.prototype.baseUrl = 'http://127.0.0.1:9998'
//生产
Vue.prototype.baseUrl = 'https://hou.rtld.cc'


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
