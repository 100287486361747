<template>
  <div id="app">
    <div style="border:1px solid black">
      <span style="font-size:16px">用户名:{{userInfo.userName}}</span>
    </div>
    <!-- 修改密码模块 -->
    <div style="border:1px solid black;margin-top:30px">
     <div>
      <span style="font-size:16px">修改密码:</span>
     </div>
     <div>
       <span>新密码</span><el-input style="width:50%" v-model="userInfo.password" type="password"></el-input> 
     </div>
     <div>
       <span>确认密码</span><el-input style="width:50%" v-model="userInfo.password2" type="password"></el-input> 
     </div>
     <div>
       <el-button @click="changePassWord">修改密码</el-button>
     </div>
    </div> 
     <!-- 修改邮箱模块 -->
    <div style="border:1px solid black;margin-top:30px">
     <div>
      <span style="font-size:16px">修改邮箱:</span>
     </div>
     <div>
       <span>旧邮箱</span><el-input style="width:50%" v-model="userInfo.email"></el-input> 
     </div>
     <div>
       <span>新邮箱</span><el-input style="width:50%" v-model="userInfo.newEmail"></el-input>
     </div>
     <div>
        <el-input type="text" v-model="userInfo.verifycodetoken" placeholder="验证码"  style="width:40%;display:inline-block"></el-input>
        <el-button :loading="!canGetCode" :disabled="!canGetCode || countDown > 0" @click="getEmailCheckCode" style="margin-left:5px">{{ countDown > 0 ? countDown : '获取验证码' }}</el-button>
     </div>    
     <div>
       <el-button @click="changeEmail">修改邮箱</el-button>
     </div>
    </div>     
  </div> 
</template>

<script>
import user from '../../api/user'
export default {
  name:'#app',
  data() {
    return {
       userInfo:{
           userName:'',
           email:'',
           newEmail:'',
           password:'',
           password2:'',
           verifycodetoken:''
       },
        //验证码使用验证
        canGetCode: true,
        countDown: 0
    }
  },
  watch: { 
  },
  mounted() {
     console.log("进行查询")
     this.getUserInfo();
  },
  destroyed() {},
  methods: {
      //获取用户信息
      getUserInfo () {
         user.getUserInfo()
            .then(resp =>{
               if (resp.data.code === 200) {
                  let result = resp.data.data
                  console.log('用户信息为'+JSON.stringify(result))
                  this.userInfo.email = result.email
                  this.userInfo.userName = result.userName
                } else {
                    this.$message.error('获取用户信息失败');
                }
        })
      },
      //后端 获取验证码
        getEmailCheckCode () {
           if(!this.canGetCode){
               return;
           }
           if(this.userInfo.newEmail == null || this.userInfo.newEmail == undefined || this.userInfo.newEmail == ''){
                 this.$message.error('请填写新邮箱');
           }
           this.canGetCode = false
           user.emailCheckCode(this.userInfo.newEmail)
            .then(resp =>{
               if (resp.data.code === 200) {
                 this.startCountDown(60);
                 this.$message.success('发送成功');
                } else {
                 this.canGetCode = true;
                  this.$message.error('发送失败请重试');
                }
            })
           .catch(() => {
             this.canGetCode = true;
             this.$message.error('发送失败请重试');
            });  
        },
        startCountDown(seconds) {
          this.countDown = seconds;
          const interval = setInterval(() => {
            if (this.countDown > 0) {
             this.countDown--;
            } else {
             clearInterval(interval);
             this.canGetCode = true;
            }
          }, 1000);
        },
        changePassWord(){
            if(this.userInfo.password == null || this.userInfo.password == undefined || this.userInfo.password == ''){
                 this.$message.error('请填写密码');
                 return;
            }
            if(this.userInfo.password2 == null || this.userInfo.password2 == undefined || this.userInfo.password2 == ''){
                 this.$message.error('请填写确认密码');
                 return;
            }
            if(this.userInfo.password !== this.userInfo.password2){
                 this.$message.error('密码与确认密码不一致');
                 return;
            }
             user.changePassWord(this.userInfo.password2)
            .then(resp =>{
               if (resp.data.code === 200) {
                    this.$message.success('修改成功');
                } else {
                    this.$message.error('修改失败');
                }
            })
        },
        changeEmail(){
            if(this.userInfo.email == null || this.userInfo.email == undefined || this.userInfo.email == ''){
                 this.$message.error('请填写旧邮箱');
            }
            if(this.userInfo.newEmail == null || this.userInfo.newEmail == undefined || this.userInfo.newEmail == ''){
                 this.$message.error('请填写新邮箱');
            }
             if(this.userInfo.verifycodetoken == null || this.userInfo.verifycodetoken == undefined || this.userInfo.verifycodetoken == ''){
                 this.$message.error('请填写验证码');
            }
            user.changeEmail(this.userInfo.email,this.userInfo.newEmail,this.userInfo.verifycodetoken)
            .then(resp =>{
               if (resp.data.code === 200) {
                    this.$message.success('修改成功');
                } else {
                   if(resp != undefined && resp.data != undefined){
                     this.$message.error(resp.data.data)
                    }
                }
            })
        }

  }
}
</script>