<template>
  <div id="app">
    <!-- <el-button type="primary" @click="addImg()">新增实体</el-button> --> 
    <el-button type="primary" @click="query()">查询</el-button>
    <el-table
      :data="tableData"
      style="width: 100%">
       <el-table-column
        prop="id"
        label="ID"
        width="180">
      </el-table-column>
      <!-- <el-table-column
        prop="createTime"
        label="日期"
        width="180">
      </el-table-column> -->
      <el-table-column
        prop="name"
        label="姓名"
        width="180">
      </el-table-column>
      <!-- <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
            <el-button type="primary" @click="deleteData(scope.row.id)">删除</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="showInYunListDto.pageNum"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="showInYunListDto.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="showInYunListDto.total"></el-pagination>
  </div> 
</template>

<script>
import shiti from '../../api/shiti'
export default {
  name:'#app',
  data() {
    return {
      tableData: [],
      showInYunListDto:{
        pageNum: 1,
        pageSize: 10,
        total:0
      },
      total: 0,
      refleshToken: '',
      token: ''
    }
  },
  watch: { 
  },
  mounted() {
     console.log("进行查询")
     this.query();
  },
  destroyed() {},
  methods: {
    getStorage(){
    },
    //不分页列表
    // query(){
    //    shiti.getYuns()
    //         .then(resp =>{
    //             //console.log("查询返回信息"+JSON.stringify(resp))
    //             if(resp.data.code === 200){
    //               this.tableData = resp.data.data
    //               this.$message.success('查询成功')
    //             }else{
    //               this.$message.error(resp.data.data)
    //             }
    //     })    
    // },
     handleCurrentChange(v) {
            this.showInYunListDto.pageNum = v;
            this.query()
     },
     handleSizeChange(v) {
            this.showInYunListDto.pageSize = v;
            this.query()
     },
    //分页列表
    query(){
       shiti.getYunOthers(this.showInYunListDto)
            .then(resp =>{
                if(resp.data.code === 200){
                  this.showInYunListDto.total = 2
                  this.tableData = resp.data.data
                  this.$message.success('查询成功')
                }else{
                  this.$message.error(resp.data.data)
                }
        })    
    },
    addImg(){
        shiti.addYun()
            .then(resp =>{
                if(resp.data.code === 200){
                  this.query()
                  this.$message.success('增加成功')
                }else{
                  this.$message.error(resp.data.data)
                }
        })    
    },
    deleteData(id){
       shiti.delete(id)
            .then(resp =>{
                if(resp.data.code === 200){
                  this.query()
                  this.$message.success('删除成功')
                }else{
                  this.$message.error(resp.data.data)
                }
        })    
    }
  }
}
</script>