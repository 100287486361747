<template>
<div>
<el-form :model="addForm" :rules="rules" ref="addFormRef" label-width="100px">
  <el-form-item label="所在省" prop="province">
    <el-select v-model="addForm.province" placeholder="请选择所在省" clearable @change="queryCity">
      <el-option v-for="area in area.provinceList" :label="area.name" :value="area.id" :key="area.name" ></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="所在市" prop="city">
    <el-select v-model="addForm.city" placeholder="请选择所在市" clearable @change="queryAreaa">
      <el-option v-for="area in area.cityList" :label="area.name" :value="area.id" :key="area.name" ></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="所在区" prop="area">
    <el-select v-model="addForm.area" placeholder="请选择所在市" clearable>
      <el-option v-for="area in area.areaList" :label="area.name" :value="area.id" :key="area.name"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="租赁方式" prop="rentMode">
    <el-radio-group v-model="addForm.rentMode">
      <el-radio-button v-for="dict in dict.rentModeList" :label="dict.value" :key="dict.name">{{dict.name}}</el-radio-button>
    </el-radio-group>
  </el-form-item>
   <el-form-item label="户型" prop="houseType">
    <el-radio-group v-model="addForm.houseType">
      <el-radio-button v-for="dict in dict.houseTypeList" :label="dict.value" :key="dict.name">{{dict.name}}</el-radio-button>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="朝向" prop="direction">
    <el-radio-group v-model="addForm.direction">
      <el-radio-button v-for="dict in dict.directionList" :label="dict.value" :key="dict.name">{{dict.name}}</el-radio-button>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="租金" prop="rental">
    <el-input type="text" style="width:200px" v-model="addForm.rental" placeholder="请输入理想租金" />
  </el-form-item>
  <el-form-item label="地址" prop="address">
    <el-input type="text" style="width:400px" v-model="addForm.address" placeholder="请输入理想地址" />
  </el-form-item>
  <el-form-item label="图片" prop="pic">
    <el-upload
      :action="baseUrl + '/house/upload'"
      :file-list="fileList"
      :on-success="onSuccess"
      :on-remove="onRemove"
      list-type="picture"
      :limit="1"
      :on-exceed="onExceed"
      :before-upload="Beforeupload"
      :headers="updloadHeader">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1mb</div>
    </el-upload>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="submitForm('addFormRef')">添加房源</el-button>
    <el-button @click="resetForm('addFormRef')">重置</el-button>
  </el-form-item>
</el-form>

</div>

</template>

<script>
import commons from '../../api/commons'
import house from '../../api/house'
export default {
    data() {
      let checkRental = (rule, rental, callback) =>{
        if(!rental) {
          return callback(new Error('请填写理想租金'))
        }
        if(!Number(rental)) {
          return callback(new Error('租金为数字'))
        }
        let reg = /^\d+.?\d{0,2}$/
        if(reg.test(rental) && rental<= 100000){
          callback()
        }else{
          return callback(new Error('租金为数字,只能有两位小数,最大为十万'))
        }
      }
        return {
            uploadHeader:{
              token: window.sessionStorage.getItem('token')
            },
            fileList: [],
            addForm: {
                pic: '',
                province: '',
                city: '',
                area: '',
                rentMode: '',
                houseType: '',
                direction: '',
                rental: '',
                address: ''
            },
            dict: {
                rentModeList: [],
                houseTypeList: [],
                directionList: []
            },
            area: {
                provinceList: [],
                cityList: [],
                areaList: []
            },
            rules: {
                province: [{type: 'number', required: true, message: '请选择省份', tirgger: 'change'}],
                city: [{type: 'number', required: true, message: '请选择市', tirgger: 'change'}],
                area: [{type: 'number', required: true, message: '请选择县区', tirgger: 'change'}],  
                rentMode: [{required: true, message: '请选择租赁方式', tirgger: 'change'}],
                houseType: [{required: true, message: '请选择户型', tirgger: 'change'}],
                direction: [{required: true, message: '请选择朝向', tirgger: 'change'}],
                rental: [{validator: checkRental, tirgger: 'blur'}],
                address: [
                  {required: true, message: '请填写地址', tirgger: 'blur'},
                  {min: 4, max: 100, message: '长度在4--100之间', tirgger: 'blur'}
                ],
                pic: [{required: true, message: '请上传图片', tirgger: 'change'}]
            }
        }
    },
    methods: {
        Beforeupload(file) {
          let isJPG = file.type === 'image/jpeg'
          let isPNG = file.type === 'image/png'
          let isLt1MB = file.size / 1024 / 1024 < 1

          if(!isLt1MB) {
            this.$message.error('文件大小不能超过1MB')
            return false
          }

          if(!isJPG && !isPNG) {
            this.$message.error('图片类型只能是jpg或png')
            return false
          }
        },
        onExceed(files, fileList) {
          this.$message.error('只能上传一个图片')
        },
        onRemove(file,fileList) {
          this.fileList = []
          this.addForm.pic = ''
          //删除后端图片
          this.$refs.addFormRef.validateField('pic')
        },
        onSuccess(response,file,fileList) {
          if(response.code===200){
            this.addForm.pic = response.data
            this.$refs.addFormRef.validateField('pic')
          }else {
            this.fileList = []
            this.$message.error(response.msg)
          }
        },
        queryDict() {
          commons.queryDict('rent_mode')
          .then(resp =>{
            this.dict.rentModeList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })

          commons.queryDict('house_type')
          .then(resp =>{
            this.dict.houseTypeList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })

          commons.queryDict('direction')
          .then(resp =>{
            this.dict.directionList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })
        },
        queryAreaa(id) {
            this.area.areaList=''
            this.addForm.area=''
            commons.queryArea(id)
            .then(resp =>{
                if(resp.data.code === 200){
                    this.area.areaList = resp.data.data
                }
            }).catch(e =>{
                console.log(e)
            })
        },
        queryCity(id) {
            console.log(this.addForm.province)
            this.area.cityList=''
            this.area.areaList=''
            this.addForm.city=''
            this.addForm.area=''
            commons.queryArea(id)
            .then(resp =>{
                console.log(resp)
                if(resp.data.code === 200){
                    this.area.cityList = resp.data.data
                }
            }).catch(e =>{
                console.log(e)
            })
        },
        queryProvince() {
            commons.queryArea(-1)
            .then(resp =>{
                if(resp.data.code===200){
                    this.area.provinceList = resp.data.data
                }
            }).catch(e =>{
                console.log(e)
            })
        },
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            house.addHouse(this.addForm)
            .then(resp =>{
              if(resp.data.code===200) {
                this.$message.success('添加成功')
                this.resetForm('addFormRef')
              }else{
                this.$message.error(resp.data.msg)
              }
            })
          } else {
            console.log(e);
            this.$message.error('添加失败')
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.fileList = []
        this.area.city = null
        this.area.area = null
      }
    },
    created() {
        this.queryProvince()
        this.queryDict()
    },
}
</script>

<style scoped>

</style>