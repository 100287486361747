<template>
<div>
    <h2>点菜历史</h2>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="序号" width="180"></el-table-column>
      <el-table-column prop="choose" label="所点菜品" width="480"></el-table-column>
      <el-table-column prop="jiage" label="总价(元)" width="180"></el-table-column>
      <el-table-column prop="dingdanhao" label="订单号" width="180"></el-table-column>
      <el-table-column prop="sfap1" label="是否安排桌位" width="180"></el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
</div>
</template>

<script>
import menu from '../../api/house'
import {DEFAULT_ICON_CONFIGS} from '@icon-park/vue'
import {Home} from '@icon-park/vue';
import {Like} from '@icon-park/vue';

const IconConfig = {...DEFAULT_ICON_CONFIGS, prefix: 'icon'}
export default {
    provide () {
        return {
            ICON_CONFIGS: IconConfig
        }
    },
    components: {
        Home,
        Like
    },
    data() {
        return {
            pageNum:1,
            pageSize:5,
            total: 0,
            tableData:[]
        }
    },
    methods: {
        querylishi(){
            menu.QueryLishi(this.pageNum,this.pageSize)
            .then(resp =>{
                this.tableData = resp.data.data.rows
                for(let i = 0;i< this.tableData.length; i++ ){
                    if(this.tableData[i].sfap===1){
                        this.tableData[i].sfap1 = '已安排'
                    }else{
                        this.tableData[i].sfap1 = '未安排'
                    }
                }
                console.log('table',this.tableData)
                this.total=resp.data.data.total
            })
        },
        handleCurrentChange(v) {
            this.pageNum = v;
            this.querylishi()
        },
        handleSizeChange(v) {
            this.pageSize = v;
            this.querylishi()
        },
    },
    created() {
        this.querylishi()
    },
}
</script>

<style scoped>

</style>