<template>
<div>
    <el-button size="medium" type="primary" icon="el-icon-circle-plus" @click="addMenu"> 添加桌子</el-button>
    <br><hr>
    <el-dialog title="添加桌子" :visible.sync="dialogFormVisible">
        <el-form :model="editform" ref="addFormRef" label-width="80px">
            <el-form-item label="人数" prop="renshu">
                <el-input v-model="editform.renshu" type="text"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="maioshu">
                <el-input v-model="editform.miaoshu" type="text"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('addFormRef')">重 置</el-button>
            <el-button type="primary" @click="add">确 定</el-button>
        </div>
    </el-dialog>
    <el-card class="box-card">
    <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
      <el-table-column prop="id" label="桌号" width="110"></el-table-column>
      <el-table-column prop="dingdan" label="在用订单号" width="110"></el-table-column>
      <el-table-column prop="renshu" label="人数" width="110"></el-table-column>
      <el-table-column prop="miaoshu" label="描述" width="110"></el-table-column>
      <el-table-column prop="state" label="状态" width="110">
          <template slot-scope="scope">
              <!-- <el-button :disabled ="scope.row.deletestate == -1"  @click="updateState(scope.row.id,scope.row.state,scope.row.dingdan)">添加订单</el-button> -->
              <el-button :disabled ="scope.row.deletestate == -1"  @click="querylishi(scope.row.id)">添加订单</el-button>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
          <template slot-scope="scope">
              <el-button :disabled ="scope.row.deletestate == -1"  @click="deletehouse(scope.row.id,scope.row.deletestate)">删除</el-button>
              <el-button :disabled ="scope.row.deletestate == -1"  @click="querybookById(scope.row.id)">修改</el-button>
          </template>
      </el-table-column>
    </el-table>
    </el-card>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[8, 1, 2, 4]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
       <el-dialog title="修改菜谱" :visible.sync="updatedialogFormVisible">
            
        <el-form :model="updateform" ref="updateFormRef" label-width="80px">
            <el-form-item label="人数" prop="renshu">
                <el-input v-model="updateform.renshu" type="text"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="miaoshu">
                <el-input v-model="updateform.miaoshu" type="text"></el-input>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="update()">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog :before-close="handleClose" title="添加订单" width="800px" :visible.sync="dingdandialogFormVisible">
        <div>
            <el-table :data="restableDatachoose" style="width: 100%">
            <el-table-column prop="id" label="序号" width="180"></el-table-column>
            <el-table-column prop="dingdanhao" label="订单号" width="180">
                 <template slot-scope="scope">
                    <a @click="tjzt(scope.row)" style="color:blue;cursor:pointer">{{scope.row.dingdanhao}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="jiage" label="总价(元)" width="180"></el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="pageNum1"
                :page-sizes="[5, 10, 20, 40]"
                :page-size="pageSize1"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total1"></el-pagination>
        </div>
    </el-dialog>
</div>
</template>

<script>
import menu from '../../api/house'
import {Like} from '@icon-park/vue';
export default {
    components: {
        Like
    },
    data() {
        return {
            upbookid:'',
            tableDatachoose:[],
            restableDatachoose:[],
            updatedialogFormVisible:false,
            dingdandialogFormVisible: false,
            total:0,
            dialogFormVisible:false,
            editform:{
                renshu: '',
                miaoshu:'',
                state:0
            },
            updateform:{
                renshu:'',
                miaoshu:''
            },
            pageNum: 1,
            pageSize: 8,
            pageNum1: 1,
            pageSize1: 5,
            total1:0,
            tableData:[]
        }
    },
    methods: {
        update() {
            menu.updatebookbyid(this.updateform)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.$message.success('修改成功')
                    this.updatedialogFormVisible=false
                    this.resetForm('updateFormRef')
                    this.queryBook()
                }
            })
        },
        tjzt(row){
            menu.updatebookzt(row,this.upbookid)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.$message.success('修改成功')
                    this.dingdandialogFormVisible=false
                    this.queryBook()
                }
            })
        },
        querylishi(row){
            this.upbookid = row
            menu.QueryLishi1(this.pageNum1,this.pageSize1,0)
            .then(resp =>{
                this.restableDatachoose = resp.data.data.rows
                /* for(let i=0;i<this.tableDatachoose.length;i++){
                    if(this.tableDatachoose[i].sfap===0){
                        this.restableDatachoose.push(this.tableDatachoose[i])
                    }
                } */
                this.total1=resp.data.data.total
            })
            this.dingdandialogFormVisible = true
        },
        handleClose(){
            this.dingdandialogFormVisible = false
            this.restableDatachoose = []
        },
        querybookById(id) {
            menu.querybookById(id)
            .then(resp =>{
                this.updateform=resp.data.data
                this.updatedialogFormVisible=true
                console.log(this.updateform)
            })
        },
        updateState(id,state,dingdan) {
            menu.updateState(id,state,dingdan)
            .then(resp =>{
                console.log(resp)
                this.queryBook()
            })
        },
        deletehouse(id,deletestate) {
            
            menu.updatedelstate(id,deletestate)
            .then(resp =>{
                this.queryBook()
            })
        },
        tableRowClassName({row,rowIndex}) {
             if (row.deletestate === -1) {
                return 'warm-row'
            }else {
                return ''
            } 
        },
        handleCurrentChange(v) {
            this.pageNum = v;
            this.queryBook();
        },
        handleSizeChange(v) {
            this.pageSize = v;
            this.queryBook();
        },
        handleCurrentChange1(v) {
            this.pageNum1 = v;
            this.querylishi(this.upbookid);
        },
        handleSizeChange1(v) {
            this.pageSize1 = v;
            this.querylishi(this.upbookid);
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.fileList = []
        },
        add() {
            menu.addBook(this.editform)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.$message.success('添加成功')
                        this.dialogFormVisible=false
                        this.queryBook(this.pageSize,this.pageSize)
                        this.resetForm('addFormRef')
                }
            })
        },
        addMenu() {
            this.dialogFormVisible = true
        },
        queryBook() {
            menu.queryBook(this.pageNum,this.pageSize)
            .then(resp =>{
                this.tableData = resp.data.data.rows
                console.log(this.tableData)
                this.total = resp.data.data.total
            })
        }
    },
    created() {
        this.queryBook()
    },
}
</script>

<style scoped>
.el-table>>>.warm-row {
  background: #ccc;
  color: #ccc;
}
</style>