import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Add from '../views/house/Add.vue'
import Query from '../views/house/Query.vue'
import Menu from '../views/order/menu.vue'
import Book from '../views/order/book.vue'
import Choose from '../views/order/choose.vue'
import Zhuce from '../views/order/zhuce.vue'
import ForgetPassword from '../views/user/changePass.vue'
import Login from '../views/Login.vue'
import Lishi from '../views/order/lishi.vue'
import Shiti from '../views/order/Shiti.vue'
import NormalChat from '../views/normalChat/normalChat.vue'
import ImgChat from '../views/imgChat/imgChat.vue'
import CodeChat from '../views/codeChat/codeChat.vue'
import CodeChatTest from '../views/codeChat/codeChatTest.vue'
import UserInfo from '../views/user/changeInfo.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path:'/zhuce',
    name:'zhuce',
    component:Zhuce
  },
  {
    path:'/',
    name:'home',
    component:Home
  },
  {
    path:'/login',
    name:'login',
    component:Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/houseAdd',
        name: 'HouseAdd',
        component: Add
      },
      {
        path:'/houseQuery',
        name:'houseQuery',
        component:Query
      },
      {
        path: '/menu',
        name: 'Menu',
        component: Menu
      },
      {
        path: '/lishi',
        name: 'Lishi',
        component: Lishi
      },
      {
        path: '/choose',
        name: 'Choose',
        component: Choose
      },
      {
        path: '/book',
        name: 'Book',
        component: Book
      },
      {
        path: '/shiti',
        name: 'Shiti',
        component: Shiti
      },
      {
        path: '/normalChat',
        name: 'normalChat',
        component: NormalChat
      },
      {
        path: '/imgChat',
        name: 'imgChat',
        component: ImgChat
      },
      {
        path: '/codeChat',
        name: 'CodeChat',
        component: CodeChat
      },
      {
        path: '/codeChat',
        name: 'CodeChat',
        component: CodeChat
      },
      {
        path: '/codeChatTest',
        name: 'CodeChatTest',
        component: CodeChatTest
      },
      {
        path: '/userInfo',
        name: 'UserInfo',
        component: UserInfo
      },
      {
        path: '/changePassword',
        name: 'ForgetPassword',
        component: ForgetPassword
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//全局前置路由守卫
 router.beforeEach((to,from,next) => {
  //先判断是不是去/login
  //如果是.login直接next
  //如果不是'/login'就判断是否登陆(看sessionStorage是否有token)
  if (to.path === '/login' || to.path === '/zhuce' || to.path === '/zhipu/demoGetDaimaWithouLogin' || to.path === '/user/forgetPassword') {
    return next()
  } else {
    let token = window.sessionStorage.getItem('token')
    if(token) {
      return next()
    } else {
      //alert('登录过期')
      //return next('login')
      return next()
    }
  }
}) 

export default router
