import request from "./request"
export default {
    //老登录
    // Login(username, password, verifycodetoken,code) {
    //     let params = new URLSearchParams()
    //     params.append("username", username)
    //     params.append("password", password)
    //     params.append("verifycodetoken",verifycodetoken)
    //     params.append("code",code)
    //     return new request({
    //         url: '/user/login',
    //         method: 'post',
    //         data: params
    //     })
    // },
    //新登录
    Login(username, password) {
        return new request({
            url: '/login?name='+username+'&pwd='+password,
            method: 'get'
        })
    },
     //注册
     Register(username, password, email, verifycodetoken) {
        return new request({
            url: '/register?name='+username+'&pwd='+password+'&email='+email+'&checkCode='+verifycodetoken,
            method: 'get'
        })
    },
    // 获取验证码
    emailCheckCode(email) {
        return new request({
            url: '/emailCheckCode?email='+email,
            method: 'get'
        })
    },
     // 获取用户信息
     getUserInfo() {
        return new request({
            url: '/user/getUserInfo',
            method: 'get'
        })
    },
     // 修改密码
     changePassWord(password) {
        return new request({
            url: '/user/changePassWord?password='+password,
            method: 'get'
        })
    },
     // 修改邮箱
     changeEmail(oldEmail, email, verifycodetoken) {
        return new request({
            url: '/user/changeEmail?oldEmail='+oldEmail+'&email='+email+'&checkCode='+verifycodetoken,
            method: 'get'
        })
    },
     // 忘记密码
     forgetPassword(email,password, verifycodetoken) {
        return new request({
            url: '/user/forgetPassword?email='+email+'&password='+password+'&checkCode='+verifycodetoken,
            method: 'get'
        })
    },
    // queryCode() {
    //     return new request({
    //         url:'/user/checkcode',
    //         //responseType: 'blob',
    //         method:'get'
    //     })
    // }
}