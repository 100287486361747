<template>
<div>
    <el-row :gutter="20">
    <el-col :span="3">
    <el-input type="text" placeholder="请输入菜名进行搜索" style="width:200px" label="搜索" v-model="sousuotj" />
    </el-col>
    <el-col :span="3">
    <el-select v-model="typetj" placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    </el-col>
    <el-col :span="1.3">
    <el-button icon="el-icon-search" type="warning" @click="queryMenu">搜索</el-button>
    </el-col>
    <el-col :span="1.6">
    <el-button type="success" icon="el-icon-circle-plus" @click="addMenu"> 添加菜品</el-button>
    </el-col>
    </el-row>
    <br><hr>
     <el-dialog title="添加菜谱" :visible.sync="dialogFormVisible">
        <el-form :model="editform" ref="addFormRef" label-width="80px">
            <el-form-item label="菜品名" prop="name">
                <el-input v-model="editform.name" type="text"></el-input>
            </el-form-item>
            <el-form-item label="菜品价格" prop="price">
                <el-input v-model="editform.price" type="text"></el-input>
            </el-form-item>
            <el-form-item label="菜品描述" prop="miaoshu">
                <el-input v-model="editform.miaoshu" type="text"></el-input>
            </el-form-item>
            <el-form-item label="菜品种类" prop="zhonglei">
                <el-select v-model="editform.zhonglei" placeholder="请选择">
                    <el-option v-for="item in zhongleiList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="图片" prop="picpath">
                <el-upload
                :action="baseUrl + '/menu/upload'"
                :file-list="fileList"
                :on-success="onSuccess"
                :on-remove="onRemove"
                list-type="picture"
                :limit="1"
                :on-exceed="onExceed"
                :before-upload="Beforeupload"
                >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1mb</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('addFormRef')">重 置</el-button>
            <el-button type="primary" @click="add">确 定</el-button>
        </div>
    </el-dialog>
    <el-card class="box-card">
        <el-row :gutter="20">
            <!-- 
                el-col:表示列，一行默认24列
                    :span:表示合并的列数，这里表示6列合并为1列，原来一共24列，则合并之后目前一行4列
                    满足4列则换行
                    此元素一般设置为循环体，则这一行循环4次之后自动换行，因为一行目前被合并后就只能盛放4列
            -->
            
            <el-col :span="6" v-for="(data, index) in tableData" :key="index">
                <!-- 
                    此处使用了ElementUI的Image图片组件
                        :src:表示图片的源
                        fit:确定图片如何适应容器框，fill / contain / cover / none / scale-down
                -->
                <el-card class="box-card">
                    <div class="ddd">
                <el-image :src="baseUrl + data.picpath" fit="scale-down" style="width:80%;height:150px;"></el-image>
                
                    <h3>{{ data.name }}</h3>
                    <el-button size="mini" @click="yulan(data.id)">  预   览  </el-button>
                    <el-button size="mini" @click="updateMenu(data.id)">  修   改  </el-button>
                    <el-button size="mini" @click="deleteMenu(data.id)">  删   除  </el-button>
                    </div>
                </el-card>
                <hr>
                
            </el-col>
            
        </el-row>
        
    </el-card>
     <el-divider></el-divider>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[8, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog
  title="预览"
  :visible.sync="yulandialogVisible"
  width="30%"
  >
  <el-card>
  <el-image :src="baseUrl + queryform.picpath" fit="scale-down" style="width:80%;height:150px;"></el-image>
  <el-row :gutter="20">

  <el-col><h4>菜名:{{queryform.name}}</h4></el-col>
  <el-col><h4>描述:{{queryform.miaoshu}}</h4></el-col>
  <el-col><h4>种类:{{queryform.zhonglei}}</h4></el-col>
  <el-col><h4>价格:{{queryform.price}}</h4></el-col>
  </el-row>
  </el-card>
  <span slot="footer" class="dialog-footer">
    <el-button @click="yulandialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="yulandialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
      <el-dialog title="修改菜谱" :visible.sync="updatedialogFormVisible">
            
        <el-form :model="updateform" ref="updateFormRef" label-width="80px">
            <el-image :src="baseUrl + updateform.picpath" fit="scale-down" style="width:80%;height:150px;"></el-image>
            <el-form-item label="菜品名" prop="name">
                <el-input v-model="updateform.name" type="text"></el-input>
            </el-form-item>
            <el-form-item label="菜品价格" prop="price">
                <el-input v-model="updateform.price" type="text"></el-input>
            </el-form-item>
            <el-form-item label="菜品描述" prop="miaoshu">
                <el-input v-model="updateform.miaoshu" type="text"></el-input>
            </el-form-item>
            <el-form-item label="菜品种类" prop="zhonglei">
                <el-select v-model="updateform.zhonglei" placeholder="请选择">
                    <el-option v-for="item in zhongleiList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="图片" prop="picpath">
                <el-upload
                :action="baseUrl + '/menu/upload'"
                :file-list="fileList"
                :on-success="onupdateSuccess"
                :on-remove="onRemove"
                list-type="picture"
                :limit="1"
                :on-exceed="onExceed"
                :before-upload="Beforeupload"
                >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1mb</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="update()">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import menu from '../../api/house'
export default {
    data() {
        return {
            sousuotj: '',
            typetj: '',
            options: [{
                value: '鲁菜',
                label: '鲁菜'
                }, {
                value: '川菜',
                label: '川菜'
                }, {
                value: '粤菜',
                label: '粤菜'
                }, {
                value: '湘菜',
                label: '湘菜'
                }, {
                value: '秘制菜系',
                label: '秘制菜系'
                }, {
                value: '酒水',
                label: '酒水'
                }, {
                value: '主食',
                label: '主食'
                }, {
                value: '',
                label: '全部'
            }],
            dialogVisible:false,
            yulandialogVisible:false,
            updatedialogFormVisible:false,
            tableData:[],
            fileList: [],
            queryform:{
                id:'',
                name: '',
                price: '',
                miaoshu: '',
                zhonglei: '',
                picpath: ''
            },
            editform: {
                id:'',
                name: '',
                price: '',
                miaoshu: '',
                zhonglei: '',
                picpath: ''
            },
            updateform: {
                id:'',
                name: '',
                price: '',
                miaoshu: '',
                zhonglei: '',
                picpath: ''
            },
            dialogFormVisible: false,
            zhongleiList:[{
                value: '鲁菜',
                label: '鲁菜'
                }, {
                value: '川菜',
                label: '川菜'
                }, {
                value: '粤菜',
                label: '粤菜'
                }, {
                value: '湘菜',
                label: '湘菜'
                }, {
                value: '秘制菜系',
                label: '秘制菜系'
                }, {
                value: '酒水',
                label: '酒水'
                }, {
                value: '主食',
                label: '主食'
                }],
                value: '',
            pageNum:1,
            pageSize:8,
            total: 0,
        }
        
    },
    methods: {
        queryByidd() {
            this.yulandialogVisible=true
        },
        update() {
                menu.updateById(this.updateform)
                .then(resp =>{
                    if(resp.data.code===200) {
                        this.$message.success('修改成功')
                        this.updatedialogFormVisible=false
                        this.queryMenu()
                    }else{
                    this.$message.error(resp.data.msg)
                    this.updatedialogFormVisible = false
                }
                })
        },
        updateMenu(id) {
            
            menu.queryById(id)
            .then(resp =>{
                this.updateform = resp.data.data
                
                this.updatedialogFormVisible=true
                
            })
        },
        yulan(id) {
            
            menu.queryById(id)
            .then(resp =>{
                
                
                this.yulandialogVisible=true
                this.queryform=resp.data.data
            })
        },
        deleteMenu(id) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
                }).then(() => {
            menu.deleteMenu(id)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.$message.success('删除成功')
                    this.queryMenu()
                }
            })
            })
            
        },
        queryMenu() {
            menu.queryMenu(this.pageNum,this.pageSize,this.typetj,this.sousuotj)
            .then(resp =>{
                
                this.tableData = resp.data.data.rows
                this.total=resp.data.data.total

            })
        },
        handleCurrentChange(v) {
            this.pageNum = v;
            this.queryMenu()
        },
        handleSizeChange(v) {
            this.pageSize = v;
            this.queryMenu()
        },
        addMenu() {
            this.dialogFormVisible = true
        },
        add() {
            
            menu.addMenu(this.editform)
            .then(resp =>{
                
                if(resp.data.code===200) {
                    this.$message.success('添加成功')
                    this.dialogFormVisible = false
                    this.resetForm('addFormRef')
                    this.queryMenu()
                }else{
                    this.$message.error(resp.data.msg)
                    this.dialogFormVisible = false
                    this.resetForm('addFormRef')
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.fileList = []
        },
        Beforeupload(file) {
          let isJPG = file.type === 'image/jpeg'
          let isPNG = file.type === 'image/png'
          let isLt1MB = file.size / 1024 / 1024 < 1

          if(!isLt1MB) {
            this.$message.error('文件大小不能超过1MB')
            return false
          }

          if(!isJPG && !isPNG) {
            this.$message.error('图片类型只能是jpg或png')
            return false
          }
        },
        onExceed(files, fileList) {
          this.$message.error('只能上传一个图片')
        },
        onRemove(file,fileList) {
          this.fileList = []
          this.editform.picpath = ''
          //删除后端图片
          
        },
        onupdateSuccess(response,file,fileList) {
          if(response.code===200){
            this.updateform.picpath = response.data
          }else {
            this.fileList = []
            this.$message.error(response.msg)
          }
        },
        onSuccess(response,file,fileList) {
          if(response.code===200){
            this.editform.picpath = response.data
            
          }else {
            this.fileList = []
            this.$message.error(response.msg)
          }
        },
    },
    created() {
        this.queryMenu()
    },
}
</script>

<style scoped>

</style>