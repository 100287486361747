import request from"./request"


export default{
    normalQuestion(param) {
        return new request({
            url:'/zhipu/normalChat',
            data:param,
            method:'post'
        })
    },
    imgQuestion(param) {
        return new request({
            url:'/zhipu/imgChat',
            data:param,
            method:'post'
        })
    },
    question(param) {
        return new request({
            url:'/zhipu/demoGetDaima',
            data:param,
            method:'post'
        })
    },
    questionTest(msg) {
        return new request({
            url:'/zhipu/demoGetDaimaWithouLogin?msg='+msg,
            method:'get'
        })
    }
}