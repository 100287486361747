<template>
<el-container class="et-container">
  <el-header>
    <div>
    <img src="../assets/logo.png">
    <span style="margin-left: 8px">cdg代码生成器</span>
    </div>
    <el-button v-if="userName == '' " type="primary" @click="toLogin">登录</el-button>
    <span v-if="userName != '' ">欢迎你:{{userName}}</span>
    <el-button v-if="userName != '' " type="danger" @click="out">退出</el-button>
  </el-header>
  <el-container>

    
    <el-aside width="200px"> 
      <el-menu
      router
      default-active="1"
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>导航菜单</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="/menu"><i class="el-icon-dish-1"></i>菜谱管理</el-menu-item>
          <el-menu-item index="/book"><i class="el-icon-tableware"></i>餐桌管理</el-menu-item>
          <el-menu-item index="/choose"><i class="el-icon-knife-fork"></i>点菜管理</el-menu-item>
          <el-menu-item index="/lishi"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>点菜历史</el-menu-item> -->
          <!-- <el-menu-item index="/shiti"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>实体一览</el-menu-item> -->
          <el-menu-item index="/normalChat"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>普通AI问答</el-menu-item>
          <el-menu-item index="/imgChat"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>文生图</el-menu-item>
          <!-- <el-menu-item index="/codeChatTest"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>AI代码生成(试用)</el-menu-item> -->
          <el-menu-item index="/codeChat"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>AI代码生成</el-menu-item>
          <el-menu-item index="/userInfo"><i><file-date theme="outline" size="19" fill="#ffffff" :strokeWidth="1"/></i>用户信息</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      
    </el-menu></el-aside>
    <el-main>
      
      <router-view />
    </el-main>
    
  </el-container>
  <!-- <el-footer style="background-color:#b3c0d1">
    <div style="font-size:14px;text-align:center;margin-top:2px">
      <span>备案号</span>
    </div>
    <div style="font-size:14px;text-align:center;margin-top:2px">
      <span>邮箱</span>
    </div>
    <div style="font-size:14px;text-align:center;margin-top:2px">
      <span>还有个什么东西?</span>
    </div>

  </el-footer>   -->
</el-container>

</template>

<script>
import {FileDate} from '@icon-park/vue';
export default {
      data() {
        return {
          userName: ''
        }
     },
    components: {
        FileDate
    },
    created() {
      this.getUserName();
    },
    methods: {
      getUserName(){
         let username = window.sessionStorage.getItem('username')
         console.log('username为'+username)
         if(username != undefined && username != null && username != ''){
           this.userName = username
         }else{
           this.userName = ''
         }
      },
      toLogin(){
        this.$router.push("/login")
      },
      out() {
        if (window.confirm("确认退出登录?")) {
         window.sessionStorage.removeItem('userId');
         window.sessionStorage.removeItem('token');
         window.sessionStorage.removeItem('refleshToken');
         window.sessionStorage.removeItem('username');
         this.$router.push("/login")
        }
      }
  },
}
</script>

<style scoped>
 .et-container{
   height: 100%;
 }
  .el-header{
    background-color: #B3C0D1;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
  }
  .el-header div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-aside{
    background-color: rgb(84, 92, 100);
    color: #333;
  }

  .el-menu{
    border-right: 0px;
    
  }
</style>