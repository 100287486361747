import request from "./request"

export default {
    queryArea(pid) {
        pid = pid || 0
        return new request({
            url: '/area/' + pid,
            method: 'get'
        })
    },
    queryDict(groupId) {
        return new request({
            url: '/dict/' + groupId,
            method: 'get'
        })
    }
}
