import axios from 'axios'

import router from '../router'

const request = axios.create({
    //开发
    //baseURL: 'http://127.0.0.1:9998',
    //实际
    baseURL: 'https://hou.rtld.cc',
})

 //请求拦截器
request.interceptors.request.use(config =>{
    //如果访问的是/login接口,放行
    if (config.url === '/login' || config.url === '/zhuce' || config.url === '/zhipu/demoGetDaimaWithouLogin' || config.url === '/user/forgetPassword') {
        return config
    }else {
        let userId = window.sessionStorage.getItem('userId')
        config.headers.userId = userId
        let token = window.sessionStorage.getItem('token')
        config.headers.token = token
        return config
    }
    //如果不是/login接口则取出
}),error =>{
    Promise.reject('请求异常')
}




request.interceptors.response.use(resp =>{
    //判断相应接口是否是/login
    if(resp.config.url === '/login' || resp.config.url === '/zhuce' || resp.config.url === '/zhipu/demoGetDaimaWithouLogin' || resp.config.url === '/user/forgetPassword') {
        return resp
    }else {
        if(resp.data.code === 999 && router.history.current.path != '/login') {
            router.replace('/login')
            Promise.reject(resp.data.msg)
        }else {
            return resp
        }
    }
}, error => {
    if(error == 'Error: Request failed with status code 403'){
        //alert('登录过期')
        if (window.confirm("未登录,请登录后进行使用")) {
                router.replace('/login')
        }else{
                
        }
            
    }else{
            Promise.reject('服务异常')
    }
}) 

export default request