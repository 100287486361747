<template>
<div>
    <el-card>
    <el-form :model="queryList" ref="queryForm">
        <el-row :gutter="10">
        <el-col :span="8">
        <el-form-item label="所在省" prop="province">
            <el-select v-model="queryList.province" placeholder="请选择所在省" clearable @change="queryCity">
                <el-option v-for="area in area.provinceList" :label="area.name" :value="area.id" :key="area.name" ></el-option>
            </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="8">
        <el-form-item label="所在市" prop="city">
            <el-select v-model="queryList.city" placeholder="请选择所在市" clearable @change="queryAreaa">
                <el-option v-for="area in area.cityList" :label="area.name" :value="area.id" :key="area.name" ></el-option>
            </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="8">
        <el-form-item label="所在区" prop="area">
            <el-select v-model="queryList.area" placeholder="请选择所在市" clearable @change="queryform">
                <el-option v-for="area in area.areaList" :label="area.name" :value="area.id" :key="area.name"></el-option>
            </el-select>
        </el-form-item>
        </el-col>
        </el-row>
        <el-form-item label="租赁方式" prop="rentMode">
            <el-radio-group v-model="queryList.rentMode" @change="QueryList">
                <el-radio-button v-for="dict in dict.rentModeList" :label="dict.value" :key="dict.name">{{dict.name}}</el-radio-button>
                <el-radio-button  label='' >不限</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="租金" prop="rental">
            <el-checkbox-group v-model="queryList.rentalList">
                <el-checkbox v-for="item in dict.rentalList" :label="item.value" :key="item.name">{{item.name}}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="户型" prop="houseType">
            <el-checkbox-group v-model="queryList.houseTypeList" @change="QueryList">
                <el-checkbox v-for="dict in dict.houseTypeList" :label="dict.value" :key="dict.name">{{dict.name}}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="朝向" prop="direction">
            <el-checkbox-group v-model="queryList.directionList" @change="QueryList">
                <el-checkbox v-for="dict in dict.directionList" :label="dict.value" :key="dict.name">{{dict.name}}</el-checkbox>          
            </el-checkbox-group>
        </el-form-item>
        <el-row>
            <el-col :span="2">
                <el-button type="primary" @click="() =>{pageNum = 1; QueryList()}">查询</el-button>
            </el-col>
            <el-col :span="2">
                <el-button type="primary" @click="resetqueryList">重置</el-button>
            </el-col>
        </el-row>
    </el-form>
    </el-card>
    <el-card class="box-card">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="areaname" label="所在区" width="110"></el-table-column>
      <el-table-column prop="rentModeName" label="方式" width="110"></el-table-column>
      <el-table-column prop="rental" label="租金(元)" width="110"></el-table-column>
      <el-table-column prop="houseTypeName" label="户型" width="110"></el-table-column>
      <el-table-column prop="directionName" label="朝向" width="110"></el-table-column>
      <el-table-column label="图片" width="110">
          <template slot-scope="scope">
              <div>
                  <el-image :src="baseUrl + scope.row.pic"
                  style="height:100px"/>
              </div>
          </template>
      </el-table-column>
      <el-table-column prop="address" label="详细地址" width="130"></el-table-column>
      <el-table-column prop="create_time" label="日期" width="100"></el-table-column>
      <el-table-column label="操作" width="180">
          <template slot-scope="scope">
              <el-button @click="deletehouse(scope.row.id)">删除</el-button>
              <el-button @click="showEditform(scope.row)">修改</el-button>
          </template>
      </el-table-column>
    </el-table>
    </el-card>
     <el-divider></el-divider>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryList.pageNum"
        :page-sizes="[3, 1, 2, 4]"
        :page-size="queryList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog title="修改房源" :visible.sync="dialogFormVisible">
        <el-form :model="editform" label-width="80px">
            <el-form-item label="所在省">
                <el-select v-model="editform.province" disabled>
                    <el-option v-for="province in area.provinceList" :key="province.name" :value="province.id" :label="province.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所在市">
                <el-select v-model="editform.city" disabled>
                    <el-option v-for="city in editArea.cityList" :key="city.name" :value="city.id" :label="city.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所在区">
                <el-select v-model="editform.area" disabled>
                    <el-option v-for="area in editArea.areaList" :key="area.name" :value="area.id" :label="area.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="方式">
                <el-radio-group v-model="editform.rentMode">
                    <el-radio-button v-for="dict in dict.rentModeList" :key="dict.value" :label="dict.value">{{dict.name}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="户型">
                <el-radio-group v-model="editform.houseType">
                    <el-radio-button v-for="dict in dict.houseTypeList" :key="dict.value" :label="dict.value">{{dict.name}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="朝向">
                <el-radio-group v-model="editform.direction">
                    <el-radio-button v-for="dict in dict.directionList" :key="dict.value" :label="dict.value">{{dict.name}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="租金">
                <el-input v-model="editform.rental" type="text"></el-input>
            </el-form-item>
            <el-form-item label="地址">
                <el-input v-model="editform.address" type="text"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="update">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import house from '../../api/house'
import commons from '../../api/commons'
export default {
    data() {
        return {
            dialogFormVisible: false,
            tableData:[],
            queryList: {
                rentMode: '',
                houseTypeList: [],
                directionList: [],
                rentalList: [],
                province: '',
                city: '',
                area: '',
            },
            area: {
                provinceList: [],
                cityList: [],
                areaList: []
            },
            dict: {
                rentModeList: [],
                houseTypeList: [],
                directionList: [],
                rentalList: []
            },
            pageNum: 1,
            pageSize: 3,
            total: 0,
            editform: {
                id: '',
                province: '',
                city: '',
                area: '',
                rentMode: '',
                houseType: '',
                direction: '',
                rental: '',
                address: ''
            },
            editArea: {
                cityList: [],
                areaList: []
            },
        }
    },
    methods: {
        update() {
            house.updateHouse(this.editform)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.$message.success('修改成功')
                    this.QueryList()
                    this.dialogFormVisible = false
                } else {
                    this.$message.error('修改失败')
                }
            }).catch(e =>{

            })
        },
        showEditform(row) {
            this.editform.id = row.id
            this.editform.province = row.province
            commons.queryArea(row.province)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.editArea.cityList = resp.data.data
                    this.editform.city = row.city
                    commons.queryArea(row.city)
                    .then(resp =>{
                        this.editArea.areaList = resp.data.data
                        this.editform.area = row.area
                        this.editform.rentMode = row.rentMode
                        this.editform.houseType = row.houseType
                        this.editform.direction = row.direction
                        this.editform.rental = row.rental
                        this.editform.address = row.address
                        this.dialogFormVisible = true
                    }).catch(e =>{
                        console.log(e)
                    })
                }
            }).catch(e =>{
                console.log(e)
            })
        },
        resetqueryList() {
            this.$refs.queryform.resetFields()
            this.pageNum = 1
            this.QueryList()
        },
        deletehouse(id) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
                }).then(() => {
                house.deletehouse(id)
                .then(resp =>{
                if(resp.data.code===200) {
                    this.$message.success('删除成功')
                    this.QueryList()
                }else{
                    this.$message.error(resp.data.msg);
                }
            }).catch(e =>{
                console.log(e)
            })
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消删除'
                });          
            });
        },
        queryform() {
            this.QueryList()
        },
        queryAreaa(id) {
            this.area.areaList=''
            this.queryList.area=''
            commons.queryArea(id)
            .then(resp =>{
                if(resp.data.code === 200){
                    this.area.areaList = resp.data.data
                }
            }).catch(e =>{
                console.log(e)
            })
            this.QueryList()
        },
        queryCity(id) {
            this.area.cityList= ''
            this.area.areaList= ''
            this.queryList.city= ''
            this.queryList.area= ''
            commons.queryArea(id)
            .then(resp =>{
                if(resp.data.code === 200){
                    this.area.cityList = resp.data.data
                }
            }).catch(e =>{
                console.log(e)
            })
            this.QueryList()
        },
        queryProvince() {
            commons.queryArea(-1)
            .then(resp =>{
                if(resp.data.code===200){
                    this.area.provinceList = resp.data.data
                }
            }).catch(e =>{
                console.log(e)
            })
        },
        queryDict() {
          commons.queryDict('rent_mode')
          .then(resp =>{
            this.dict.rentModeList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })
          //
          commons.queryDict('house_type')
          .then(resp =>{
            this.dict.houseTypeList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })
          //
          commons.queryDict('direction')
          .then(resp =>{
            this.dict.directionList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })
          
          commons.queryDict('rental')
          .then(resp =>{
            this.dict.rentalList = resp.data.data
          }).catch(e =>{
            console.log(e)
          })
        },
        handleCurrentChange(v) {
            this.pageNum = v;
            this.QueryList();
        },
        handleSizeChange(v) {
            this.pageSize = v;
            this.QueryList();
        },
        QueryList() {
            house.QueryList(this.queryList,this.pageNum,this.pageSize)
            .then(resp =>{
                if(resp.data.code===200) {
                    console.log(resp)
                    this.tableData = resp.data.data.rows
                    this.total = resp.data.data.total
                    console.log(resp.data.data)
                }
            }).catch(e =>{
                console.log(e)
            })
        }
    },
    created() {
        this.QueryList()
        this.queryDict()
        this.queryProvince()
    },
}
</script>

<style scoped>

</style>