<template>
   <div>
    <!-- <div>
        <el-input v-model="msg" placeholder="请输入问题"></el-input>
        <el-button  @click="question()">发送</el-button>
    </div> -->
    <!-- <span>
        要为 `id` 为 `box3` 的 `el-button` 设置左边距为 `20px`，你可以使用 CSS 来实现。以下是一种常见的方法：\n\n1. 确保你的 HTML 结构中有 `el-button`，例如：\n\n```html\n<el-button id=\"box3\">按钮</el-button>\n```\n\n2. 在你的 CSS 文件或 `<style>` 标签中添加以下样式：\n\n```css\n#box3 {\n    margin-left: 20px;\n}\n```\n\n或者，如果你是在内联样式中设置：\n\n```html\n<el-button id=\"box3\" style=\"margin-left: 20px;\">按钮</el-button>\n```\n\n这样就可以将 `id` 为 `box3` 的 `el-button` 的左边距设置为 `20px`。\n\n如果你使用的是 Vue.js 或者其他框架，确保你的 CSS 样式被正确引入，并且选择器能够正确匹配到目标元素
    </span> -->
    <!-- 聊天盒子 -->
    <div class="chatContent" ref="chatBox">
      <ul>
        <li v-for="(item, index) in info" :key="index" :class="[item.is_robot == 0 ? 'left' : 'right']">
          <span v-if="item.is_robot == 1">{{ item.content }}</span>
          <!-- <span v-if="item.is_robot == 0">{{ item.content }}</span>  -->
          <span v-if="item.is_robot == 0">
           <span v-for="(theItem, index) in item.parsedContent" :key="index" style="display: block;">
            <div v-if=" theItem.type === 'text'">
                {{ theItem.content }}
            </div>
            <div v-if=" theItem.type === 'background'">
             <div class="background-div">
              {{ theItem.content }}
             </div>
            </div>
           </span>
          </span>
        </li>
      </ul>
    </div>
    <!-- 底部盒子 -->
    <div style="width:100%">
      <el-input type="textarea" v-model="msg" style="width:80%"
       :autosize="{ minRows: 2, maxRows: 15}"
       @keyup.enter.native = "question()"
       placeholder="您的问题"></el-input>
      <el-button :loading="load" style="margin-left:5%" @click="question()">发送</el-button>
    </div>
   </div> 
</template>
    
<script>
import chat from '../../api/chat'
    export default {
        data() {
            return {
              msg:'',
              load:false,
              // info:[
              //   {"is_robot":0,"content":"你好,可以提出代码问题"}
              // ],
              info:[],
              theAnswerMsg:''
            }
        },
      computed: {
        
      },
        methods: {
          parseContent(info) {
           let parsed= [];
           let regex= /```(.*?)```/gs;
           let lastIndex=0;
           let match;

           while ((match=regex.exec(info.content)) !==null) {
              // Add preceding text as normal text
            if (match.index>lastIndex) {
              let precedingText = info.content.substring(lastIndex, match.index);
              parsed.push({ type:'text', content:precedingText });
            }

            // Add matched text inside background div
            let matchedText=match[0];
            let innerText=match[1];
            parsed.push({ type:'background', content:innerText });

            lastIndex=match.index+matchedText.length;
          }

           // Add remaining text
           if (lastIndex<info.content.length) {
            let  remainingText = info.content.substring(lastIndex);
            parsed.push({ type:'text', content:remainingText });
           }

           info.parsedContent=parsed;
         },
          question(){
            this.load = true
            let theMsg = {
              "is_robot":1,"content":this.msg
            }
            this.info.push(theMsg)
            let theParam = {
              "msg" : this.msg
            }
            chat.question(theParam).then(resp =>{
               if(resp != undefined && resp.data != undefined && resp.data.code === 200){
                this.load = false
                this.theAnswerMsg = resp.data.data
                let answerMsg = {
                  "is_robot":0,"content":resp.data.data
                }
                this.parseContent(answerMsg);
                this.info.push(answerMsg)
                this.msg = ''
               
               }else{
                 this.load = false
                 if(resp != undefined && resp.data != undefined){
                   this.$message.error(resp.data.data)
                 }
               }
            })
          }
          
        },
       
    }
</script>
    
<style scoped>
.chatContent {
  height: 735px;
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 20px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

li.left {
  margin-right: 20px;
}

li.left span {
  display: inline-block;  
  border-radius: 0 15px 15px;
  white-space: pre-wrap;
  background-color: rgba(255,165,0);
  padding: 10px 15px;
}

li.right {
  margin-left: 20px;
  text-align: right;
}

li.right span {
  display: inline-block;
  border-radius: 15px 0 15px 15px;
  background-color: rgba(73, 154, 41, 0.1);
  padding: 10px 15px;
}

li+li {
  margin-top: 20px;
}

.ql-container.ql-snow {
  border: 0;
}

.ql-editor {
  padding: 0;
}

.background-div {
  background-color: white;
  padding: 5px;
  border: 1pxsolid#ccc;
  display: block;
}

</style>
