<template>
<div>
    <el-row :gutter="20">
    <el-col :span="3">
    <el-input type="text" placeholder="请输入菜名进行搜索" style="width:200px" label="搜索" v-model="sousuotj" />
    </el-col>
    <el-col :span="3">
    <el-select v-model="typetj" placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    </el-col>
    <el-col :span="1.5">
    <el-button type="primary" icon="el-icon-search" @click="queryMenu">搜索</el-button>
    </el-col>
    <!-- <el-col :span="1.5">
    <el-button type="primary" icon="el-icon-check" @click="finish">点完了</el-button> 
    </el-col> -->
    <el-col :span="1.5">
    <el-button type="danger" icon="el-icon-close" @click="qvxiao">全部取消</el-button>
    </el-col>
    <el-col :span="1.5">
    <el-button type="danger" icon="el-icon-s-order" @click="looked">查看点菜</el-button> 
    </el-col>
    </el-row>
    <hr>
    <el-card class="box-card">
        <el-row :gutter="20">
            <el-col :span="6" v-for="(data, index) in tableData" :key="index">
                <el-card class="box-card">
                <div class="tu">
                <el-image class="tupian" :src="baseUrl + data.picpath" fit="scale-down" style="width:80%;height:150px;"></el-image>
                </div>    
                <div class="tu">
                    <h3>菜名:{{ data.name }}</h3>
                    <h3>价格:{{ data.price }}</h3>
                </div>
                    <el-button size="mini" @click="choose(data)">  点   菜  </el-button>
                    <el-button size="mini" @click="xinagxi(data.id)">  菜品详细  </el-button>
                </el-card>
                <hr>
            </el-col>
        </el-row>
    </el-card>
    <el-divider></el-divider>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[8, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog
  title="已点菜品"
  :visible.sync="dialogVisible"
  width="1200px"
  :before-close="handleClose">
    <el-card class="box-card">
        <el-row :gutter="20">
            <el-col :span="8" v-for="(data, index) in looktable" :key="index">
                <el-card class="box-card">
                    <el-image :src="baseUrl + data.picpath" fit="scale-down" style="width:80%;height:150px;"></el-image>
                    <h3>菜名:{{ data.name }}</h3>
                    <h3>价格:{{ data.price }}</h3><!-- 
                    <h3>描述:{{ data.miaoshu }}</h3>
                    <h3>种类:{{ data.zhonglei }}</h3> -->
                    <el-button type="danger" @click="del(data)">删除菜品</el-button>
                </el-card>
                <hr>
            </el-col>
        </el-row>
        <h2>总价:{{ this.zongjia }}元</h2>
    </el-card>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" :disabled="chooseMap.length<0" @click="look">确 定 点 菜</el-button>
  </span>
</el-dialog>
 <el-dialog
  title="菜品详细"
  :visible.sync="xiangzidialog"
  width="30%"
  :before-close="handleClose">
    <el-card class="box-card">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-image :src="baseUrl + xiangxitable.picpath" fit="scale-down" style="width:100%;height:200px;"></el-image>
                    <h3>菜名:{{ xiangxitable.name }}</h3>
                    <h3>价格:{{ xiangxitable.price }}</h3>
                    <h3>描述:{{ xiangxitable.miaoshu }}</h3>
                    <h3>种类:{{ xiangxitable.zhonglei }}</h3>   
            </el-col>
        </el-row>
    </el-card>
    <span slot="footer" class="dialog-footer">
    <el-button @click="xiangzidialog = false,count = ''">取 消</el-button>
    <el-button type="primary" @click="xiangzidialog = false" >确 定</el-button>
  </span>
</el-dialog>
</div>
</template>

<script>
import menu from '../../api/house'
export default {
    data() {
        return {
            sousuotj: '',
            typetj: '',
            options: [{
                value: '鲁菜',
                label: '鲁菜'
                }, {
                value: '川菜',
                label: '川菜'
                }, {
                value: '粤菜',
                label: '粤菜'
                }, {
                value: '湘菜',
                label: '湘菜'
                }, {
                value: '秘制菜系',
                label: '秘制菜系'
                }, {
                value: '酒水',
                label: '酒水'
                }, {
                value: '主食',
                label: '主食'
                }, {
                value: '',
                label: '全部'
                }],
            xiangxitable:{},
            xiangzidialog:false,
            table:[],
            tableData: [],
            pageNum: 1,
            pageSize: 8,
            total: 0,
            chooseMap: [],
            looktable: [],
            dialogVisible: false,
            zongjia:0,
            count:''
        }
    },
    methods: {
        xinagxi(id) {
            menu.queryById(id)
            .then(resp =>{
                this.xiangxitable=resp.data.data
                this.xiangzidialog=true
            })
        },
        del(data) {
            let count = this.chooseMap.indexOf(data.id)
            //this.chooseMap.splice(count,1)
            let count1 = this.chooseMap.indexOf(data.id)
            this.chooseMap.splice(count1,1)
            this.zongjia=this.zongjia-data.price
            //console.log('下标',count1)
            //console.log('id',id)
            //console.log('删除',this.table)
            //console.log('map',this.chooseMap)
            menu.look(this.chooseMap)
            .then(resp =>{
               this.looktable = resp.data.data
            })
        },
        looked() {
            menu.look(this.chooseMap)
            .then(resp =>{
               this.looktable = resp.data.data
            })
               this.dialogVisible = true
        },
        qvxiao() {
            this.chooseMap=[]
            this.zongjia=0
        },
        look() {
            this.count=''
            menu.choose(this.chooseMap)
            .then(resp =>{
                if(resp.data.code===200) {
                    this.dialogVisible = false  
                    this.chooseMap =[]
                    this.zongjia=0
                    alert('点菜成功,请联系前台付钱!!!')
                }else{
                    this.$message.error('系统错误')
                }
            })
            
        },
        handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
        },
        finish() {
            this.count='A'
            alert('点菜成功')
            this.chooseMap = this.chooseMap
        },
        choose(data) {
            this.chooseMap.push(data.id)
            this.zongjia = this.zongjia+data.price
            this.chooseMap = this.chooseMap
        },
        queryMenu() {
            menu.queryMenu(this.pageNum,this.pageSize,this.typetj,this.sousuotj)
            .then(resp =>{
                this.tableData = resp.data.data.rows
                this.total=resp.data.data.total
            })
        },
        handleCurrentChange(v) {
            this.pageNum = v;
            this.queryMenu()
        },
        handleSizeChange(v) {
            this.pageSize = v;
            this.queryMenu()
        },
    },
    created() {
        this.queryMenu()
    },
}
</script>

<style scoped>
.tu {
    border-style: solid;
    border-width: 1px;
    border-color: #F2F6FC;
}
.tupian {
    height: 100%;
    width: 100%;
}
</style>