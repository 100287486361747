import request from "./request"

export default{
    updatebookbyid(book) {
        return new request({
            url:'/menu/updatebook',
            method:'post',
            data:book
        })
    },
    querybookById(id) {
        return new request({
            url:`/menu/querybookbyid?id=${id}`,
            method:'get'
        })
    },
    updateState(id,state,dingdan) {
        return new request({
            url:`/menu/updatestate?id=${id}&state=${state}&dingdan=${dingdan}`,
            method: 'get'
        })
    },
    updatedelstate(id,deletestate) {
        return new request({
            url:`/menu/updatedelstate?id=${id}&deletestate=${deletestate}`,
            method: 'get'
        })
    } ,
    addBook(book) {
        return new request({
            url:'/menu/addbook',
            method:'post',
            data: book
        })
    },
    queryBook(pageNum,pageSize,type,sousuo) {
        return new request({
            url:`/menu/querybook?pageNum=${pageNum}&pageSize=${pageSize}`,
            method:'get'
        })
    },
    updateById(menu) {
        return new request({
            url:`/menu/updatemenu?id=${menu.id}`,
            method:'post',
            data:menu
        })
    },
    queryById(id) {
        return new request({
            url:`/menu/querybyid?id=${id}`,
            method:'get',
            
        })
    },
    deleteMenu(id) {
        return new request({
            url:`/menu/delete?id=${id}`,
            method:'get'
        })
    },

    queryMenu(pageNum,pageSize,type,sousuo) {
        return new request({
            url:`/menu?pageNum=${pageNum}&pageSize=${pageSize}&type=${type}&sousuo=${sousuo}`,
            method:'get'
        })
    },
    addMenu(menu) {
        return new request({
            url:'/menu/add',
            method:'post',
            data:menu
        })
    },
    addHouse(house) {
        return new request({
            url:'/house',
            method:'post',
            data:house
        })
    },
    updateHouse(house) {
        return new request({
            url:'/house',
            method:'put',
            data:house
        })
    },
    zhuce(user) {
        return new request({
            url:'/user/add',
            method:'post',
            data:user
        })
    },
    look(chooseMap) {
        return new request({
            url:'/choose/look',
            method:'post',
            data:{
                chooseMap:chooseMap
            }
        })
    },
    choose(chooseMap) {
        return new request({
            url:'/choose/add',
            method:'post',
            data:{
                chooseMap:chooseMap
            }
        })
    },
    deletehouse(id){
        return new request({
            url:'/house/' + id,
            method: 'delete'
        })
    },
    QueryList(queryList,pageNum,pageSize){
        return new request({
            url: `/house?pageNum=${pageNum}&pageSize=${pageSize}`,
            method: 'get',
            params:{
                province:queryList.province,
                city:queryList.city,
                area:queryList.area,
                pageNum,
                pageSize,
                rentMode:queryList.rentMode,
                rentalList:queryList.rentalList.join(','),
                houseTypeList:queryList.houseTypeList.join(','),
                directionList:queryList.directionList.join(',')

            },
        })
    },
    QueryLishi(pageNum,pageSize){
        return new request({
            url: `/choose/querychoose?pageNum=${pageNum}&pageSize=${pageSize}`,
            method: 'get'
        })
    },
    QueryLishi1(pageNum,pageSize,sfap){
        return new request({
            url: `/choose/querychoose?pageNum=${pageNum}&pageSize=${pageSize}&sfap=${sfap}`,
            method: 'get'
        })
    },
    updatebookzt(choose,bookid){
        return new request({
            url: `/choose/updatezt?bookid=${bookid}`,
            method: 'post',
            data:choose
        })
    }
}