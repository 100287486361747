import request from "./request"

export default{
    getYuns() {
        return new request({
            url:'/showYun/getYuns',
            method:'get'
        })
    },
    getYunOthers() {
        return new request({
            url:'/otherProject/getYunOthers',
            method:'get'
        })
    },
    getYunList(showInYunListDto) {
        return new request({
            url:'/showYun/getYunList',
            method:'post',
            data:showInYunListDto
        })
    },
    addYun() {
        return new request({
            url:'/showYun/addYun',
            method:'get'
        })
    },
    delete(id) {
        return new request({
            url:'/showYun/deleteYun?id='+id,
            method:'get'
        })
    }
}